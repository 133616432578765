import {createRouter, createWebHistory} from 'vue-router';

// const UserAuth = defineAsyncComponent(() => import('./pages/auth/UserAuth.vue'));
import Home from './components/pages/Home.vue';
import About from './components/pages/About.vue';
import Exteriors from './components/pages/Exteriors.vue';
import Interiors from './components/pages/Interiors.vue';
import Contact from './components/pages/Contact.vue';
import PrivacyPolicy from './components/pages/PrivacyPolicy.vue';
import store from './store/index.js';

const router = createRouter({
    history: createWebHistory(),
    routes: [
        { path: '/', redirect: '/home'},
        { path: '/home', component: Home, meta: { transitionName: 'home' } },
        { path: '/about', component: About },
        { path: '/exteriors', component: Exteriors },
        { path: '/interiors', component: Interiors },
        { path: '/contact', component: Contact},
        { path: '/privacyPolicy', component: PrivacyPolicy},
        { path: '/home4', component: Home },
        // { path: '/:notFound(.*)', component: NotFound}
    ],
    scrollBehavior(from, to, savedPosition) {
        const fromHistory = Boolean(savedPosition);
    
        if (fromHistory && store.state.routerHistory.length > 0) {
          store.state.routerHistory.splice(-1, 1);
        } else {
          store.state.routerHistory.push(from);
        }
    
        return savedPosition || { x: 0, y: 0 };
      },
    
});
export default router;