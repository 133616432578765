<template>
  <a href="#main"  class="skip">Skip to main content</a>
  <div class="background" :style="styleHandler('background')" id="main">    
    <div class="block1"></div>
    <div class="header" ref="header">
      <div class="hCenter">
        <div class="hTitle" ref="hTitle" :style="styleHandler('hTitle')">
          Rexroth
        </div>
        <div class="hLine"></div>
        <div
          class="hSubTitle"
          ref="hSubTitle"
          :style="styleHandler('hSubTitle')"
        >
          Building & Interiors LLC
        </div>
      </div>
    </div>
    <div class="block1"></div>
    <div class="frame">
        <div class="fTop" ref="fTop"></div>
      <div class="fNav">
        <div
          class="navButtons"
          ref="navButtons"
          :style="styleHandler('navButtons')"
        >
          <div class="navButton" @click="dispatcher('home')" >
            <div :class="selected==='home'? 'navLine':''">Home</div>
          </div>
          <div class="navButton" @click="dispatcher('about')" >
            <div :class="selected==='about'? 'navLine':''">About</div>
          </div>
          <div class="navButton" v-if="!isMobile" @click="dispatcher('exteriors')">
            <div :class="selected==='building'? 'navLine':''">Exteriors</div>
          </div>
          <div class="navButton" v-if="!isMobile" @click="dispatcher('interiors')">
            <div :class="selected==='interiors'? 'navLine':''">Interiors</div>
          </div>
          <div class="navButton1" v-if="isMobile" >
            <div @click="projectClick" :class="selected==='projects'? 'navLine':''">Projects</div>
            <div class="ddBox" v-if="projectDropDown">
              <div class="ddButton" @click="dispatcher('interiors')">
                Interiors
              </div>
              <div class="ddLine"></div>
              <div class="ddButton" @click="dispatcher('exteriors')">
                Exteriors
              </div>
            </div>
          </div>    
          <div class="navButton" @click="dispatcher('contact')">
            <div :class="selected==='contact'? 'navLine':''">Contact</div>
          </div>
          <div class="navButton" >
            <div :class="selected==='privacyPolicy'? 'navLine':''">Privacy Policy</div>
          </div>
        </div>
      </div>
      <div class="fLine" ref="fLine"></div>
      <div class="mainPage" > <!-- :style="{ height: h1 + 'px'}"-->
        <div class="lSide"></div>
        <div class="centerView">
          <div class="centerView1" :class="transform===1?'centerViewIn' : transform===2?'centerViewOut':''">
          <div style="height: 6%"></div>
          <div class="phrase1" ref="phrase1" :style="styleHandler('phrase1')">              
            <!-- <div class="phrase1Text" v-html="aboutText"></div> -->
            <div class="b1">
              <!-- <div class="b2" @click="this.$store.dispatch('setShowPP', { showpp: false })">X</div> -->
              <h1>Privacy Policy</h1>
              <p role="heading"><span class="t1" role="heading">Effective Date:</span> {{new Date().toLocaleString('default', {month: 'long', day: 'numeric', year: 'numeric'})}}</p>

              <p>At Rexroth Building and Interiors ("we", "our", "us"), your privacy is important to us. This Privacy Policy explains how we collect, use, and protect your personal information when you visit our website <a aria-label="Opens rexroth-building.com in a new tab." href="https://rexroth-building.com/" target="_blank">rexroth-building.com</a> and use the contact form to reach us.</p>

              <h2>1. Information We Collect</h2>
              <p role="heading">We may collect personal information through:</p>
              <ul>
                  <li>Contact Form: First Name, Last Name, Email Address, Phone, and Your Message.</li>
                  <li>Google Analytics: Information about your use of our website, such as pages visited, time spent, browser type, IP address, and device type.</li>
                  <li>Cookies and Tracking Technologies: Our website may use cookies to improve your browsing experience and collect analytics data.</li>
              </ul>

              <h2>2. How We Use Your Information</h2>
              <p role="heading">The information we collect is used to:</p>
              <ul>
                  <li>Respond to inquiries and messages submitted through the contact form.</li>
                  <li>Analyze website traffic and usage patterns to improve our website.</li>
                  <li>Ensure the website functions properly and provide a better user experience.</li>
              </ul>

              <h2>3. Google Analytics and Cookies</h2>
              <p role="heading">We use Google Analytics to collect information about how visitors use our website. This helps us understand traffic patterns and improve user experience. Google Analytics uses cookies to track your interactions with our site. The data collected may include:</p>
              <ul>
                  <li>Your IP address (anonymized where applicable).</li>
                  <li>Pages visited and time spent on each page.</li>
                  <li>Browser type, operating system, and device used.</li>
              </ul>

              <p>You can learn more about how Google collects and processes data <a aria-label="Opens Google Policies in a new tab." href="https://policies.google.com/technologies/partner-sites" target="_blank" rel="noopener noreferrer">here</a>.</p>

              <h3>Managing Cookies</h3>
              <p>You can control or disable cookies through your browser settings. Note that disabling cookies may affect the functionality of the website.</p>

              <h2>4. How We Share Your Information</h2>
              <p>We do not sell, trade, or rent your personal information to third parties. However, we may disclose your information if required by law or to protect our legal rights.</p>

              <h2>5. Data Security</h2>
              <p>We take reasonable steps to protect your personal information, but no method of transmission over the internet is entirely secure. Please contact us if you have security concerns.</p>

              <h2>6. Your Rights</h2>
              <p>You have the right to:</p>
              <ul>
                  <li>Access the personal data we hold about you.</li>
                  <li>Request correction or deletion of your personal data.</li>
                  <li>Opt-out of analytics tracking by disabling cookies in your browser or using tools like the <a aria-label="Opens google anayltics opt-out in a new tab." href="https://tools.google.com/dlpage/gaoptout" target="_blank" rel="noopener noreferrer">Google Analytics Opt-out Browser Add-on</a>.</li>
              </ul>

              <h2>7. External Links</h2>
              <p>Our website may contain links to third-party websites. We are not responsible for the privacy practices or content of those sites.</p>

              <h2>8. Changes to This Privacy Policy</h2>
              <p>We may update this policy periodically. Any changes will be posted on this page with an updated effective date. We encourage you to review this policy regularly.</p>

              <h2>9. Contact Us</h2>
              <p>If you have questions about this policy, contact us at: <a aria-label="Opens email in a new tab." href="mailto:pnrexroth1@gmail.com">pnrexroth1@gmail.com</a></p>            
              <footer>
                  <p style="width: 100%; text-align: center;">&copy; Rexroth Building and Interiors LLC {{new Date().getFullYear()}}. All rights reserved.</p>
              </footer>
            </div>
          </div>
          </div>
        </div>
        <div class="rSide"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      hTitleFont: null,
      hSubTitleFont: null,
      navFontHeight: null,
      phrase1Height: null,
      phrase2Height: null,
      minHeight: null,
      minWidth: null,
      selected: "privacyPolicy",      
      transform: 0,
      projectDropDown:false,
      h1:0,
      h2:0,
    };
  },
  // computed: {
  //   h2() {
  //     return window.innerHeight + "px";
  //   }
  // },
  methods: {
    async handleResize() {      
        this.hTitleFont = this.$refs.hTitle.clientHeight * 0.7;
        this.hSubTitleFont = this.$refs.hSubTitle.clientHeight * 0.5;
        this.minWidth = screen.width * 0.65;
        this.minHeight = screen.height * 0.65;                    
    },
    async handleResize1() {
      this.navFontHeight = this.$refs.navButtons.clientHeight * (this.isMobile? 0.35 : 0.40);
    },
    handleResize2() {      
      console.log(this.$refs.fLine.getBoundingClientRect().bottom);
      this.h1=window.innerHeight - this.$refs.fLine.getBoundingClientRect().bottom +"px";
      console.log("About: HR2");
      console.log(this.h1);
      this.h2 = window.innerHeight + "px";
    },
    windowSize(){
        if(window.innerWidth<=900){
            this.isMobile=true;
        } else {
            this.isMobile=false;
        }
        console.log(this.isMobile);
    },
    async resizeHandler(){
      if (this.$refs.hTitle) {
        this.handleResize();
        await this.handleResize();
        await this.handleResize1();
        this.handleResize2();
      }
    },
    styleHandler(name) {
      if (name === "background") {
        return {
          "min-height": this.minHeight + "px",
          "min-width": this.minWidth + "px",
        };
      } else if (name === "hTitle") {
        return { "font-size": this.hTitleFont + "px" };
      } else if (name === "hSubTitle") {
        return { "font-size": this.hSubTitleFont + "px" };
      } else if (name === "navButtons") {
        return { "font-size": this.navFontHeight + "px" };
      }
      // else if (name === "phrase1"){
      //   return { "font-size": this.phrase1Height + "px" }
      // }
    },
    projectClick(){
      this.selected='projects';
      this.projectDropDown=true;
    },
    dispatcher(name) {
      this.projectDropDown=false;
      window.scrollTo({
        top:0,
        left:0,
        behavior: 'smooth'
      });
      this.selected=name;
      this.transform=2;
      setTimeout( () => this.$router.push({path: '/'+name}),500);
    },
    orChange(){
      console.log("Orientation Change");
      this.resizeHandler();
    }
  },
  
  mounted() {
    this.resizeHandler();    
    window.addEventListener('orientationchange', this.orChange);
    window.addEventListener("resize", this.resizeHandler);
    window.addEventListener('resize',this.windowSize);            
    this.windowSize();       
  },
  created() {    
    this.transform=1;
  },
};
</script>
<style scoped>
.navLine {
  border-bottom:2px solid black;
}
.b1 {
  padding:40px;
  font-size: .8em;
}
.background {
  display: block;
  height: 100%;
  width: 100%;
  background-color: rgb(221, 210, 178);
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  overflow-y:hidden;
  box-sizing: border-box;
}

h1 {
  margin:0;
  width: 100%;
  text-align:center;
}
h2 {
  font-size: 1.15em;
  text-decoration: underline;
  font-weight:bold;
}
/* Header */
.header {
  display: flex;
  height: 0;  
}
.hCenter {
  justify-content: center;
  height: 100%;
  margin: auto;
  padding: 0;
  text-align: center;
  font-family: "Times New Roman", Times, serif;
  text-shadow: 0 1px 2px rgba(65, 65, 65, 0.16),
    0 4px 6px rgba(129, 129, 129, 0.45);
}
.hTitle {
  height: 51.5%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  color: rgb(170, 0, 0);
}
.hLine {
  height: 1.5%;
  margin: 0;
  padding: 0;
  background-color: black;
}
.hSubTitle {
  height: 46%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  color: rgb(170, 0, 0);
  white-space: nowrap;
  margin-left: 10px;
  margin-right: 10px;
}

/**** Frame ****/
.frame {
  width: 104%;
  height: 85%;
  transform: translateX(-2%);
  
}
.fTop {
  width: 100%;
  height: 1%;
  background-color: white;
  border: 1px solid grey;
  border-radius: 5px;
  box-sizing: border-box;
}
.fNav {
  width: 97%;
  height: 7%;
  margin-left: 1.5%;
  background-color: white;
  border-left: 1px solid grey;
  border-right: 1px solid grey;
  border-top: 1px solid grey;
  box-sizing: border-box;
  box-shadow: inset 0px 6px 4px -4px #888;
}
.navButtons {
  display: flex;
  height: 100%;
  width:80%;
  margin-left:10%;
  align-items: center;
  justify-content: space-evenly;
  color: black;
  font-family: sans-serif;
}
.navButton {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  width:auto;
}

.navButton:hover {
  cursor: pointer;
  background-color: rgba(189, 189, 189, 0.253);
}
.navButton:active {
  cursor: pointer;
  background-color: rgba(156, 156, 156, 0.253);
}
.fLine {
  width: 98.2%;
  height: 1%;
  margin-left: 0.9%;
  background-color: white;
  border: 1px solid grey;
  box-sizing: border-box;
}
.mainPage {
  width: 97%;
  margin-left: 1.5%;
  height: 109%;
  overflow-y:hidden;
}
.lSide {
  width: 5%;
  float: left;
  height: 100%;
  background-color: white;
  border: 1px solid grey;
  box-sizing: border-box;
}
.rSide {
  width: 5%;
  float: right;
  height: 100%;
  background-color: white;
  border: 1px solid grey;
  box-sizing: border-box;
}
.centerView {  
  width: 90%;
  float: left;
  height: 100%;
  background-image: url("~@/assets/background/owood1.jpg");
  background-size: cover;
  overflow-y:scroll;
  /* margin-bottom:10px; */
}
.phrase1 {
  width: 70%;
  margin-left: 15%;  
  margin-bottom:5%;
  background-color: rgb(221, 210, 178);  
  
  color: black;
  text-align: left;
  font-family: "Times New Roman", Times, serif;
  font-size: 1.8em;
  margin-bottom:5%;
}
.phrase1Text {
  padding:5%;
}
.skip {
  position: absolute;
  left: -10000px;
  top: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
}
.skip:focus {
  left:0;
  width: auto;
  height: auto;
}

.centerView1{
  width:100%;
  height:100%;
}
.centerViewIn {
  animation: centerViewIn .5s forwards;
}
.centerViewOut {
  animation: centerViewOut .5s forwards;
}
@keyframes centerViewIn {
  from { opacity:0; }
  to { opacity:1; }
}
@keyframes centerViewOut {
  from { opacity:1; }  
  to { opacity:0; }
}
@media only screen and (max-width: 900px) {
  /* #app {
    overflow-y: none !important;   
  } */
  .b1 {
    padding:10px;
  }
  .background {
    height: v-bind(h2) !important;
    overflow-y:hidden !important;
  }
  .mainPage {
    height:v-bind('h1') !important;
  }
  .fLine {
    height: 1vh;
  }
  .fTop {
    height: 1vh;
  }
  .centerView{
    /* height:fit-content !important; */
  }
  .navButtons {
    width:100%;
    margin:0;    
    padding:0;
    justify-content: space-evenly;
  }
  .fNav {
    height:5.95vh;
  }
  .navButton, .navButton1 {
    /* font-size:.8em; */
  }
  .navButton1 {
    flex-direction: column;            
  }
  .ddButton {
    /* border:1px solid black;f */
    text-align:center;
    width:110%;
    height:1.5em;
    background-color:white;
    display:flex;
    justify-content: center;
    align-items: center;
  }
  .ddBox {
    position:fixed;
    z-index:12; 
    background-color:white;    
  }
  .ddLine {
    width:90%;
    margin-left:5%;
    border:.5px solid black;

  }
  .phrase1 {
    width:90%;
    margin-left:5%;
    font-size: 1.1em;
  }
}
</style>