<template>
  <a href="#main"  class="skip">Skip to main content</a>
  <div class="background" :style="styleHandler('background')" id="main">    
    <!-- <transition :name="doTransform ? 'block1' : ''" appear> -->
      <div class="block1"></div>
    <!-- </transition> -->
    <!-- <transition :name="doTransform ? 'headerHeight' : ''" appear> -->
    <div class="header" ref="header">
      <div class="hCenter">
        <div class="hTitle" ref="hTitle" :style="styleHandler('hTitle')">
          Rexroth
        </div>
        <div class="hLine"></div>
        <div
          class="hSubTitle"
          ref="hSubTitle"
          :style="styleHandler('hSubTitle')"
        >
          Building & Interiors LLC
        </div>
      </div>
    </div>
      <div class="block1"></div>
      <div class="frame">
          <div class="fTop" ref="fTop"></div>
        <div class="fNav">
          <div
            class="navButtons"
            ref="navButtons"
            :style="styleHandler('navButtons')"
          >
            <div class="navButton" @click="dispatcher('home')" >
              <div :class="selected==='home'? 'navLine':''">Home</div>
            </div>
            <div class="navButton" >
              <div :class="selected==='about'? 'navLine':''">About</div>
            </div>
            <div class="navButton" v-if="!isMobile" @click="dispatcher('exteriors')">
              <div :class="selected==='building'? 'navLine':''">Exteriors</div>
            </div>
            <div class="navButton" v-if="!isMobile" @click="dispatcher('interiors')">
              <div :class="selected==='interiors'? 'navLine':''">Interiors</div>
            </div>
            <div class="navButton1" v-if="isMobile" >
              <div @click="projectClick" :class="selected==='projects'? 'navLine':''">Projects</div>
              <div class="ddBox" v-if="projectDropDown">
                <div class="ddButton" @click="dispatcher('interiors')">
                  Interiors
                </div>
                <div class="ddLine"></div>
                <div class="ddButton" @click="dispatcher('exteriors')">
                  Exteriors
                </div>
              </div>
            </div>    
            <div class="navButton" @click="dispatcher('contact')">
              <div :class="selected==='contact'? 'navLine':''">Contact</div>
            </div>
            <div class="navButton" @click="dispatcher('privacyPolicy')" >
              <div :class="selected==='privacyPolicy'? 'navLine':''">Privacy Policy</div>
            </div>
          </div>
        </div>
        <div class="fLine" ref="fLine"></div>
        <!-- <transition :name="doTransform? 'mainPage' : ''" appear> -->
        <div class="mainPage" > <!-- :style="{ height: h1 + 'px'}"-->
          <div class="lSide"></div>
          <div class="centerView">
            <div class="centerView1" :class="transform===1?'centerViewIn' : transform===2?'centerViewOut':''">
            <div style="height: 6%"></div>
            <div class="phrase1" ref="phrase1" :style="styleHandler('phrase1')">              
              <div class="phrase1Text" v-html="aboutText"></div>
            </div>
            </div>
          </div>
          <div class="rSide"></div>
        </div>
        <!-- </transition> -->
      </div>
    <!-- </transition> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      hTitleFont: null,
      hSubTitleFont: null,
      navFontHeight: null,
      phrase1Height: null,
      phrase2Height: null,
      minHeight: null,
      minWidth: null,
      selected: "about",
      aboutText:
        "Rexroth Building & Interiors is focused on creating spaces that are enjoyable, efficient, and inspiring.  We specialize in custom trim carpentry for new construction homes including staircases and built-ins but also do remodels, room additions and stud frame accessory buildings.<br><br>" +
        "As the owner I (Paul Rexroth) started the company in 2019 to serve customers by providing excellence in custom carpentry.  I work mostly alone, allowing me to bring my own experience, craftsmanship, and attention to detail to every project.  I enjoy the challenges of custom work, whether it be a solid hardwood staircase or a built-in cabinet made to fit a unique space or serve a special purpose.<br><br>" +  
        "Although finish carpentry and whole house trim packages are my expertise, I also do remodeling. With a background in engineering, I enjoy working with customers on floorplans to make their home layout more enjoyable and functional.  As a father of five, I understand the changing seasons of life and the way a home needs to be adapted.  Whether it be a large addition, a kitchen that needs to be redone, or simply a cosmetic update, I would love to talk to you about how to transform your space.<br><br>" +
        "Rexroth Building & Interiors is based in Nappanee Indiana and serves the surrounding area.  Our goal is to provide top quality products and excellent workmanship at a fair price.  We operate out of a 1500 sq. ft shop set up for custom carpentry and finishing.  Many of the products are made or finished in-house to allow quick turn around for custom components and to maximize quality control.  A variety of local custom shops are used to source other hardwood components.<br><br>" +
        "So, what is the next project you are dreaming of: a custom interior for your new home, a sunroom addition with a cathedral ceiling, an accessory building?  Call <a href='/contact'>Rexroth Building & Interiors</a> today and choose to make it a space that inspires.",
      transform: 0,
      projectDropDown:false,
      h1:0,
      h2:0,
    };
  },
  // computed: {
  //   h2() {
  //     return window.innerHeight + "px";
  //   }
  // },
  methods: {
    async handleResize() {      
        this.hTitleFont = this.$refs.hTitle.clientHeight * 0.7;
        this.hSubTitleFont = this.$refs.hSubTitle.clientHeight * 0.5;
        this.minWidth = screen.width * 0.65;
        this.minHeight = screen.height * 0.65;                    
    },
    async handleResize1() {
      this.navFontHeight = this.$refs.navButtons.clientHeight * (this.isMobile? 0.35 : 0.40);      
    },
    handleResize2() {
      // this.h1=window.innerHeight - this.$refs.fLine.getBoundingClientRect().bottom +"px";
      
      console.log(this.$refs.fLine.getBoundingClientRect().bottom);
      this.h1=window.innerHeight - this.$refs.fLine.getBoundingClientRect().bottom +"px";
      // this.h1 = window.innerHeight - this.$refs.fLine.getBoundingClientRect().bottom +"px";      
      console.log("About: HR2");
      console.log(this.h1);
      // this.h2 = window.innerHeight - this.$refs.fTop.getBoundingClientRect().top + "px";
      this.h2 = window.innerHeight + "px";
    },
    windowSize(){
        // console.log(window.innerWidth);
        if(window.innerWidth<=900){
            this.isMobile=true;
        } else {
            this.isMobile=false;
        }
        console.log(this.isMobile);
    },
    async resizeHandler(){
      if (this.$refs.hTitle) {
        this.handleResize();
        await this.handleResize();
        await this.handleResize1();
        this.handleResize2();
      }
      // window.addEventListener('load',this.handleResize2);
    },
    styleHandler(name) {
      if (name === "background") {
        return {
          "min-height": this.minHeight + "px",
          "min-width": this.minWidth + "px",
        };
      } else if (name === "hTitle") {
        return { "font-size": this.hTitleFont + "px" };
      } else if (name === "hSubTitle") {
        return { "font-size": this.hSubTitleFont + "px" };
      } else if (name === "navButtons") {
        return { "font-size": this.navFontHeight + "px" };
      }
      // else if (name === "phrase1"){
      //   return { "font-size": this.phrase1Height + "px" }
      // }
    },
    projectClick(){
      this.selected='projects';
      this.projectDropDown=true;
    },
    dispatcher(name) {
      this.projectDropDown=false;
      window.scrollTo({
        top:0,
        left:0,
        behavior: 'smooth'
      });
      this.selected=name;
      this.transform=2;
      setTimeout( () => this.$router.push({path: '/'+name}),500);
    },
    orChange(){
      console.log("Orientation Change");
      this.resizeHandler();
    }
  },
  
  mounted() {
    this.resizeHandler();    
    // window.addEventListener('orientationchange', this.resizeHandler);
    window.addEventListener('orientationchange', this.orChange);
    window.addEventListener("resize", this.resizeHandler);
    // window.addEventListener("resize", this.handleResize2);
    window.addEventListener('resize',this.windowSize);            
    this.windowSize();       
  },
  created() {    
    this.transform=1;
  },
};
</script>
<style scoped>
.navLine {
  border-bottom:2px solid black;
}
.background {
  display: block;
  height: 100%;
  width: 100%;
  background-color: rgb(221, 210, 178);
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  overflow-y:hidden;
  box-sizing: border-box;
  /* transform: translateY(-14%); */
}

/* Header */
.header {
  display: flex;
  height: 0;  
}
.hCenter {
  justify-content: center;
  height: 100%;
  margin: auto;
  padding: 0;
  text-align: center;
  font-family: "Times New Roman", Times, serif;
  text-shadow: 0 1px 2px rgba(65, 65, 65, 0.16),
    0 4px 6px rgba(129, 129, 129, 0.45);
}
.hTitle {
  height: 51.5%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  color: rgb(170, 0, 0);
}
.hLine {
  height: 1.5%;
  margin: 0;
  padding: 0;
  background-color: black;
}
.hSubTitle {
  height: 46%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  color: rgb(170, 0, 0);
  white-space: nowrap;
  margin-left: 10px;
  margin-right: 10px;
}

/**** Frame ****/
.frame {
  width: 104%;
  height: 85%;
  transform: translateX(-2%);
  
}
.fTop {
  width: 100%;
  height: 1%;
  background-color: white;
  border: 1px solid grey;
  border-radius: 5px;
  box-sizing: border-box;
}
.fNav {
  width: 97%;
  height: 7%;
  margin-left: 1.5%;
  background-color: white;
  border-left: 1px solid grey;
  border-right: 1px solid grey;
  border-top: 1px solid grey;
  box-sizing: border-box;
  box-shadow: inset 0px 6px 4px -4px #888;
}
.navButtons {
  display: flex;
  height: 100%;
  width:80%;
  margin-left:10%;
  align-items: center;
  justify-content: space-evenly;
  color: black;
  font-family: sans-serif;
}
.navButton {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  width:auto;
}

.navButton:hover {
  cursor: pointer;
  background-color: rgba(189, 189, 189, 0.253);
}
.navButton:active {
  cursor: pointer;
  background-color: rgba(156, 156, 156, 0.253);
}
.fLine {
  width: 98.2%;
  height: 1%;
  margin-left: 0.9%;
  background-color: white;
  border: 1px solid grey;
  box-sizing: border-box;
}
.mainPage {
  width: 97%;
  margin-left: 1.5%;
  height: 109%;
  overflow-y:hidden;
}
.lSide {
  width: 5%;
  float: left;
  height: 100%;
  background-color: white;
  border: 1px solid grey;
  box-sizing: border-box;
}
.rSide {
  width: 5%;
  float: right;
  height: 100%;
  background-color: white;
  border: 1px solid grey;
  box-sizing: border-box;
}
.centerView {  
  width: 90%;
  float: left;
  height: 100%;
  background-image: url("~@/assets/background/owood1.jpg");
  background-size: cover;
  overflow-y:scroll;
  /* margin-bottom:10px; */
}
.phrase1 {
  width: 70%;
  margin-left: 15%;  
  margin-bottom:5%;
  background-color: rgb(221, 210, 178);  
  
  color: black;
  text-align: left;
  font-family: "Times New Roman", Times, serif;
  font-size: 1.8em;
  margin-bottom:5%;
}
.phrase1Text {
  padding:5%;
}
.skip {
  position: absolute;
  left: -10000px;
  top: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
}
.skip:focus {
  left:0;
  width: auto;
  height: auto;
}

.centerView1{
  width:100%;
  height:100%;
}
.centerViewIn {
  animation: centerViewIn .5s forwards;
}
.centerViewOut {
  animation: centerViewOut .5s forwards;
}
@keyframes centerViewIn {
  from { opacity:0; }
  to { opacity:1; }
}
@keyframes centerViewOut {
  from { opacity:1; }  
  to { opacity:0; }
}
@media only screen and (max-width: 900px) {
  /* #app {
    overflow-y: none !important;   
  } */
  .background {
    height: v-bind(h2) !important;
    overflow-y:hidden !important;
  }
  .mainPage {
    height:v-bind('h1') !important;
  }
  .fLine {
    height: 1vh;
  }
  .fTop {
    height: 1vh;
  }
  .centerView{
    /* height:fit-content !important; */
  }
  .navButtons {
    width:100%;
    margin:0;    
    padding:0;
    justify-content: space-evenly;
  }
  .fNav {
    height:5.95vh;
  }
  .navButton, .navButton1 {
    /* font-size:.8em; */
  }
  .navButton1 {
    flex-direction: column;            
  }
  .ddButton {
    /* border:1px solid black;f */
    text-align:center;
    width:110%;
    height:1.5em;
    background-color:white;
    display:flex;
    justify-content: center;
    align-items: center;
  }
  .ddBox {
    position:fixed;
    z-index:12; 
    background-color:white;    
  }
  .ddLine {
    width:90%;
    margin-left:5%;
    border:.5px solid black;

  }
  .phrase1 {
    width:90%;
    margin-left:5%;
    font-size: 1.1em;
  }
}
</style>