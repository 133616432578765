<template>
  <a href="#main"  class="skip">Skip to main content</a>
  <div class="background" :style="styleHandler('background')" id="main">    
    <!-- <transition :name="doTransform ? 'block1' : ''" appear> -->
      <div class="block1"></div>
    <!-- </transition> -->
    <!-- <transition :name="doTransform ? 'headerHeight' : ''" appear> -->
    <div class="header" ref="header">
      <div class="hCenter">
        <div class="hTitle" ref="hTitle" :style="styleHandler('hTitle')">
          Rexroth
        </div>
        <div class="hLine"></div>
        <div
          class="hSubTitle"
          ref="hSubTitle"
          :style="styleHandler('hSubTitle')"
        >
          Building & Interiors LLC
        </div>
      </div>
    </div>
      <div class="block1"></div>
      <div class="frame">
          <div class="fTop"></div>
        <div class="fNav">
          <div
            class="navButtons"
            ref="navButtons"
            :style="styleHandler('navButtons')"
          >
            <div class="navButton" @click="dispatcher('home')" >
              <div :class="selected==='home'? 'navLine':''">Home</div>
            </div>
            <div class="navButton" @click="dispatcher('about')">
              <div :class="selected==='about'? 'navLine':''">About</div>
            </div>
            <div class="navButton" v-if="!isMobile" @click="dispatcher('exteriors')">
              <div :class="selected==='building'? 'navLine':''">Exteriors</div>
            </div>
            <div class="navButton" v-if="!isMobile" @click="dispatcher('interiors')">
              <div :class="selected==='interiors'? 'navLine':''">Interiors</div>
            </div>
            <div class="navButton1" v-if="isMobile">
              <div @click="projectClick" :class="selected==='projects'? 'navLine':''">Projects</div>
              <div class="ddBox" v-if="projectDropDown">
                <div class="ddButton" @click="dispatcher('interiors')">
                  Interiors
                </div>
                <div class="ddLine"></div>
                <div class="ddButton" @click="dispatcher('exteriors')">
                  Exteriors
                </div>
              </div>
            </div>    
            <div class="navButton" @click="dispatcher('contact')">
              <div :class="selected==='contact'? 'navLine':''">Contact</div>
            </div>
            <div class="navButton" @click="dispatcher('privacyPolicy')" >
              <div :class="selected==='privacyPolicy'? 'navLine':''">Privacy Policy</div>
            </div>
          </div>
        </div>
        <div class="fLine"></div>
        <!-- <transition :name="doTransform? 'mainPage' : ''" appear> -->
        <div class="mainPage">
          <div class="lSide"></div>
            <div class="centerView" ref="cv">
              <div class="centerView1" :class="transform===1?'centerViewIn' : transform===2?'centerViewOut':''">
                <!-- <div style="height: 6%"></div>
                <div class="phrase1">
                  <div class="phrase1">Coming Soon...</div>
                </div>-->            
                <div v-if="selectedPhoto===-1">
                  <div class="photoRow" v-for="n in imgIndex" :key="n">   
                    <div class="folderBlock">
                      <img alt="Photo showing exteriors." class="photoImg" :ref="'img'+ (((n-1)*3)+1)" @click="photoSelected((n-1)*3)" :src="require('../../assets/ExteriorsOptimized' + getImagePath((n-1)*3))" />
                    </div>         
                    <div class="folderBlock">
                      <img v-if="((n-1)*3)+1 < imgLength" :ref="'img'+ (((n-1)*3)+2)" @click="photoSelected(((n-1)*3)+1)" alt="Photo showing exteriors." class="photoImg" :src="require('../../assets/ExteriorsOptimized' + getImagePath(((n-1)*3)+1))" />
                    </div>
                    <div class="folderBlock">
                      <img v-if="((n-1)*3)+2 < imgLength" :ref="'img'+ (((n-1)*3)+3)" @click="photoSelected(((n-1)*3)+2)" alt="Photo showing exteriors." class="photoImg" :src="require('../../assets/ExteriorsOptimized' + getImagePath(((n-1)*3)+2))" />
                    </div>                    
                  </div>   
                  <!-- <div class="photoRow" v-for="n in imgIndex" :key="n">   
                    <div class="folderBlock" v-if="hPhoto((n-1)*3)">
                      <img class="photoImg" @click="photoSelected((n-1)*3)" :src="require('../../assets/ExteriorsOptimized' + getImagePath((n-1)*3))" />
                    </div>         
                    <div class="folderBlock" v-if="hPhoto(((n-1)*3)+1)">
                      <img v-if="((n-1)*3)+1 < imgLength" @click="photoSelected(((n-1)*3)+1)" class="photoImg" :src="require('../../assets/ExteriorsOptimized' + getImagePath(((n-1)*3)+1))" />
                    </div>
                    <div class="folderBlock" v-if="hPhoto(((n-1)*3)+2)">
                      <img v-if="((n-1)*3)+2 < imgLength" @click="photoSelected(((n-1)*3)+2)" class="photoImg" :src="require('../../assets/ExteriorsOptimized' + getImagePath(((n-1)*3)+2))" />
                    </div>                    
                  </div>   
                  <div class="photoRow" v-for="n in vImgIndex" :key="n">   
                    <div class="folderBlock">
                      <img class="photoImg" @click="photoSelected((n-1)*3)" :src="require('../../assets/ExteriorsOptimized' + getVImagePath((n-1)*3))" />
                    </div>         
                    <div class="folderBlock" v-if="((n-1)*3)+1 < vImgLength">
                      <img @click="photoSelected(((n-1)*3)+1)" class="photoImg" :src="require('../../assets/ExteriorsOptimized' + getVImagePath(((n-1)*3)+1))" />
                    </div>
                    <div class="folderBlock" v-if="((n-1)*3)+2 < vImgLength">
                      <img  @click="photoSelected(((n-1)*3)+2)" class="photoImg" :src="require('../../assets/ExteriorsOptimized' + getVImagePath(((n-1)*3)+2))" />
                    </div>                    
                  </div>                   -->
                </div>
                <div v-else>
                  <div class="liDiv">
                    <button class="leftButton" @click="leftClick">&lt;</button>
                    <button class="rightButton" @click="rightClick">></button>
                    <button class="exitButton" @click="exitClick">x</button>
                    <!-- <div class="imgBack" @click="console.log('test')"></div> -->
                    <img alt="Photo showing exteriors." class="largeImage" v-click-outside="exitClick" :src="require('../../assets/ExteriorsOptimized' + getImagePath(selectedPhoto))" />
                  </div>
                </div>    
              </div>
              <div v-if="isMobile" class="block3"></div>
            </div>            
          <div class="rSide"></div>
        </div>
        <!-- </transition> -->
      </div>
    <!-- </transition> -->
  </div>
</template>

<script>


export default {  
  data() {
    return {
      hTitleFont: null,
      hSubTitleFont: null,
      navFontHeight: null,
      phrase1Height: null,
      phrase2Height: null,
      minHeight: null,
      minWidth: null,
      selected: "building",
      aboutText:
        "Carpentry is a worldwide occupation that includes building and repairing things made from wood and other materials. A carpenter probably built the house that you live in and the building that you work in. Carpenters are located all around the world in places you would never think." +
        "Carpentry is a worldwide occupation that includes building and repairing things made from wood and other materials. A carpenter probably built the house that you live in and the building that you work in. Carpenters are located all around the world in places you would never think." +
        "Carpentry is a worldwide occupation that includes building and repairing things made from wood and other materials. A carpenter probably built the house that you live in and the building that you work in. Carpenters are located all around the world in places you would never think.",
      transform: 0,
      images: {},
      imageSize: [],
      imgLength:0,
      imgIndex:0,
      vImgIndex:0,
      vImgLength:0,
      selectedPhoto:-1,
      projectDropDown:false,
      vIndex: -1,
      vImages: {},
      h2: 0,
      // h3: 0,
    };
  },
  computed: {

  },
  methods: {
    async handleResize() {
      if (this.$refs.hTitle) {
        this.hTitleFont = this.$refs.hTitle.clientHeight * 0.7;
        this.hSubTitleFont = this.$refs.hSubTitle.clientHeight * 0.5;
        this.minWidth = screen.width * 0.65;
        this.minHeight = screen.height * 0.65;
        // this.h3 = this.$refs.cv.clientHeight + "px";
      }
    },
    isH(index){
      console.log(index);
      if(this.$refs["img"+index][0].width > this.$refs["img"+index][0].height){
          return true;
      }               
      return false; 
    },
    handleResize1() {
      this.navFontHeight = this.$refs.navButtons.clientHeight * (this.isMobile? 0.35 : 0.40);
      this.h2 = window.innerHeight + "px";      
    },
    async resizeHandler(){
      await this.handleResize();
      this.handleResize1();
    },
    styleHandler(name) {
      if (name === "background") {
        return {
          "min-height": this.minHeight + "px",
          "min-width": this.minWidth + "px",
        };
      } else if (name === "hTitle") {
        return { "font-size": this.hTitleFont + "px" };
      } else if (name === "hSubTitle") {
        return { "font-size": this.hSubTitleFont + "px" };
      } else if (name === "navButtons") {
        return { "font-size": this.navFontHeight + "px" };
      }
      // else if (name === "phrase1"){
      //   return { "font-size": this.phrase1Height + "px" }
      // }
    },
    projectClick(){
      this.selected='projects';
      this.projectDropDown=true;
    },
    resetPage(){
      this.transform=1;
      // this.selectedFolder=-1;
      this.selectedPhoto=-1;
    },
    dispatcher(name) {
      this.projectDropDown=false;
      window.scrollTo({
        top:0,
        left:0,
        behavior: 'smooth'
      });
      this.selected=name;
      this.transform=2;
      if(name==='exteriors'){        
        setTimeout(() => this.resetPage(),500);
      } else {
        setTimeout( () => this.$router.push({path: '/'+name}),500);
      }
    },
    windowSize(){
        // console.log(window.innerWidth);
        if(window.innerWidth<=900){
            this.isMobile=true;
        } else {
            this.isMobile=false;
        }
        console.log(this.isMobile);
    },
    async importAll(r) {
      function sortAlphaNum(a,b) {
        return a.localeCompare(b, 'en', {numeric: true});
      }
      var imgs = {}     
      var keys = r.keys();      
      keys = keys.sort(sortAlphaNum);
      keys.forEach(key=>(imgs[key]=r(key)));
      this.images = imgs;                  
    },
    getImagePath(index){
      return Object.keys(this.images)[index].substring(1);
    },
    getVImagePath(index){
      console.log(index);
      console.log(this.vImages);
      return Object.keys(this.vImages)[index].substring(1);
    },
    photoSelected(index){
      this.selectedPhoto=index;
    },
    async getImageSizes(){
      var img1 = Array.from(this.$el.querySelectorAll(".photoImg"));
      console.log(img1.length);
    },
    // populateImageData(index) {
    //   return new Promise((resolve) => {
    //     let img = new Image()
    //     img.src = require("../../assets/ExteriorsOptimized" + this.getImagePath(index))
    //     console.log(img.);
    //     img.onload = function() {
    //       resolve({
    //         isH: img.width > img.height
    //       })
    //     }
    //   })
    // },
    // hPhoto(index){
    //   if(!this.imageSize[index] && this.vIndex===1000){
    //     this.vIndex=index;
    //   }
    //   return this.imageSize[index];
    // },
    leftClick(){
      if(this.selectedPhoto===0){
        this.selectedPhoto=Object.keys((this.images)).length-1;
      } else {
        this.selectedPhoto = this.selectedPhoto - 1;
      }
    },
    rightClick(){
      if(this.selectedPhoto===Object.keys((this.images)).length-1){
        this.selectedPhoto=0;
      } else {
        this.selectedPhoto = this.selectedPhoto + 1;
      }
    },
    exitClick(){
      if(this.selectedPhoto != -1){
        this.selectedPhoto=-1;
      }
    },
    keyEvent(e){
      let cmd = String.fromCharCode(e.keyCode).toLowerCase();
      console.log(cmd);
    },
    orChange(){
      console.log("Orientation Change");
      this.resizeHandler();
    }
  },
  async mounted() {
    this.resizeHandler();
    window.addEventListener('orientationchange', this.orChange);
    window.addEventListener("resize", this.resizeHandler);
    this.importAll(require.context("../../assets/ExteriorsOptimized/", true, /\.jpg$/));       
        
    // this.$nextTick(() => {      
    //   console.log(this.$refs["img1"][0].height);
    //   for(var i = 1; i < Object.keys(this.images).length+1; i++){
        
    //     console.log(this.$refs["img"+1][0].width + " - " + this.$refs["img"+1][0].height);
    //   }
    // })
    
    // var stime = new Date();
    // for(var i = 0; i < Object.keys(this.images).length;i++){
    //   var img = await this.populateImageData(i);
    //   this.imageSize.push(img.isH);
    //   if(!img.isH){
    //     this.vIndex=i;
    //   }
    //   // this.imageSize.push(img.isH);
    //   // if(img.width > img.height){
    //   //   this.imageSize.push(true);
    //   // }else {
    //   //   this.imageSize.push(false);
    //   //   if(this.vIndex===-1){
    //   //     this.vIndex=i;
    //   //   }
    //   // }
    // }    
    // var etime = new Date();
    // console.log(etime - stime);
    // console.log(this.vIndex);
    // for(i = this.vIndex; i< Object.keys(this.images).length;i++){
    //   this.vImages[Object.keys(this.images)[i]] = this.images[Object.keys(this.images)[i]];
    // }            
    this.imgIndex = Math.ceil(Object.keys(this.images).length/3)
    // this.vImgIndex = Math.ceil(Object.keys(this.vImages).length/3)
    this.imgLength = Object.keys(this.images).length;
    // this.vImgLength = Object.keys(this.vImages).length;
    window.addEventListener('resize',this.windowSize);            
    this.windowSize();       
  },
  created() {
    this.transform=1;
    window.addEventListener('keydown', (e) => {
      if(this.selectedPhoto!=-1){
        if(e.key=='ArrowRight') {
          this.rightClick();
        }
        else if(e.key==='ArrowLeft'){
          this.leftClick();
        } else if(e.key==='Escape'){
          this.exitClick();
        }
      }
    })
  },
};
</script>
<style scoped>
.navLine {
  border-bottom:2px solid black;
}
.background {
  display: block;
  height: 100%;
  width: 100%;
  background-color: rgb(221, 210, 178);
  margin: 0;
  padding: 0;
    overflow-x: hidden;
  overflow-y:hidden;
  box-sizing: border-box;
}
.imgs{ 
  /* height:100%;
  width:100%; */
}
.leftButton,.rightButton {
  font-size:2em;
  color:black;
  position:absolute;  
  padding:2%;
  padding-right:1.5%;
  padding-left:1.5%;
  text-align:center;
  border-radius: 8%;  
  border:none;
  opacity:.5;  
}
.rightButton {
  right:12%;
}
.leftButton {
  left:12%;
}
.leftButton:hover, .rightButton:hover {
  opacity:1;
  cursor:pointer;
}
.exitButton {
  font-size:2em;
  color:black;
  position:absolute;
  width:5vw;
  height:5vw;
  text-align:center;
  border-radius:50%;
  border:none;
  opacity:.5;
  top:20%;
  z-index:100;
}
.exitButton:hover {
  opacity:1;
  cursor:pointer;
}
.liDiv {
  width:100%;  
  display:flex;
  justify-content: center;
  align-items: center;
  
}
.largeImage {
  max-width:95%;
  max-height:80vh;
  margin-top:5%;
  border:4px solid rgb(173, 173, 173);
  box-sizing: border-box;
}
/* .imgBack {
  background-color:green;
  width:100%;
  height:100%;
  position:fixed;
} */
.folderBlock {    
  width:29%;  
  text-align:center;  
}
.photoImg:hover {
  cursor:pointer;
  filter:brightness(.8);
}
.photoRow {
  display:flex;      
  /* height:40%;   */
  justify-content: space-evenly;  
  padding-top:1.8%;
  padding-bottom:1.8%;
}
.photoImg {  
  border:4px solid rgb(173, 173, 173);
  width:100%;  
  box-sizing:border-box;
}

/* Header */
.header {
  display: flex;
  height: 0;  
}
.hCenter {
  justify-content: center;
  height: 100%;
  margin: auto;
  padding: 0;
  text-align: center;
  font-family: "Times New Roman", Times, serif;
  text-shadow: 0 1px 2px rgba(65, 65, 65, 0.16),
    0 4px 6px rgba(129, 129, 129, 0.45);
}
.hTitle {
  height: 51.5%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  color: rgb(170, 0, 0);
}
.hLine {
  height: 1.5%;
  margin: 0;
  padding: 0;
  background-color: black;
}
.hSubTitle {
  height: 46%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  color: rgb(170, 0, 0);
  white-space: nowrap;
  margin-left: 10px;
  margin-right: 10px;
}

/**** Frame ****/
.frame {
  width: 104%;
  height: 85%;
  transform: translateX(-2%);
}
.fTop {
  width: 100%;
  height: 1%;
  background-color: white;
  border: 1px solid grey;
  border-radius: 5px;
  box-sizing: border-box;
}
.fNav {
  width: 97%;
  height: 7%;
  margin-left: 1.5%;
  background-color: white;
  border-left: 1px solid grey;
  border-right: 1px solid grey;
  border-top: 1px solid grey;
  box-sizing: border-box;
  box-shadow: inset 0px 6px 4px -4px #888;
}
.navButtons {
  display: flex;
  height: 100%;
  width:80%;
  margin-left:10%;
  align-items: center;
  justify-content: space-evenly;
  color: black;
  font-family: sans-serif;
}
.navButton {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  width:auto;
}

.navButton:hover {
  cursor: pointer;
  background-color: rgba(189, 189, 189, 0.253);
}
.navButton:active {
  cursor: pointer;
  background-color: rgba(156, 156, 156, 0.253);
}
.fLine {
  width: 98.2%;
  height: 1%;
  margin-left: 0.9%;
  background-color: white;
  border: 1px solid grey;
  box-sizing: border-box;
}
.mainPage {
  width: 97%;
  margin-left: 1.5%;
  height: 109%;
  overflow-y:hidden;
}
.lSide {
  width: 5%;
  float: left;
  height: 100%;
  background-color: white;
  border: 1px solid grey;
  box-sizing: border-box;
}
.rSide {
  width: 5%;
  float: right;
  height: 100%;
  background-color: white;
  border: 1px solid grey;
  box-sizing: border-box;
}
.centerView {
  width: 90%;
  float: left;
  height: 100%;
  background-image: url("~@/assets/background/owood1.jpg");
  background-size: cover;
  overflow-y:scroll !important;  
  /* margin-bottom:10px; */
}
.phrase1 {
  width: 80%;
  margin-left: 10%;
  /* background-color: rgb(221, 210, 178); */

  color: white;
  text-align: center;
  font-family: "Times New Roman", Times, serif;
  font-size: 1.8em;
  margin-bottom:5%;
}

.headerHeight-enter-from,
.headerHeight-leave-to {
  height:14%;
}
.headerHeight-enter-active,
.headerHeight-leave-active {
  transition: all 2s ease;
}

.block1-enter-from,
.block1-leave-to {
  height:.5%;
}

.block1-enter-active,
.block1-leave-active {
  transition: all 2s ease;
}

.frame-enter-from,
.frame-leave-to {
  width: 80%;
  margin-left:10%;  
}

.frame-enter-active,
.frame-leave-active {
  transition: all 2s ease;
}

.fTop-enter-from,
.fTop-leave-to {
  height: 3%;
}

.fTop-enter-active,
.fTop-leave-active {
  transition: all 2s ease;
}

.mainPage-enter-from,
.mainPage-leave-to {
  height:89%;
  overflow-y:none;
}

.mainPage-enter-active,
.mainPage-leave-active {
  transition: all 2s ease;
}
.skip {
  position: absolute;
  left: -10000px;
  top: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
}
.skip:focus {
  left:0;
  width: auto;
  height: auto;
}
.centerView1{
  width:100%;
  height:100%;
}
.centerViewIn {
  animation: centerViewIn .5s forwards;
}
.centerViewOut {
  animation: centerViewOut .5s forwards;
}
@keyframes centerViewIn {
  from { opacity:0; }
  to { opacity:1; }
}
@keyframes centerViewOut {
  from { opacity:1; }  
  to { opacity:0; }
}
@media only screen and (max-width: 900px) {
  .background {
    height: v-bind(h2) !important;
    min-height:initial !important;
  }
  .block3 {
    height: 30px;
  }
  .centerView {
    overscroll-behavior:contain;
  }
  .centerView1 {
    height: fit-content !important;
  }
  /* .centerView1 {
    position:relative;
  } */
  /* .centerView1 {
    height: v-bind(h3) !important;
  } */
  .navButtons {
    width:100%;
    margin:0;    
    padding:0;
    justify-content: space-evenly;
  }
  .fNav {
    height:5.95vh;
  }
  .navButton, .navButton1 {
    /* font-size:.8em; */
  }
  .navButton1 {
    flex-direction: column;            
  }
  .ddButton {
    /* border:1px solid black;f */
    text-align:center;
    width:110%;
    height:1.5em;
    background-color:white;
    display:flex;
    justify-content: center;
    align-items: center;
  }
  .ddBox {
    position:fixed;
    z-index:12; 
    background-color:white;    
  }
  .ddLine {
    width:90%;
    margin-left:5%;
    border:.5px solid black;

  }
  .leftButton, .rightButton {
    padding-right:3%;
    padding-left:3%;
  }
  .exitButton {
    width:15vw;
    height:15vw;
    padding:0;
    margin:0;
    top:15%;
    display:flex;
    justify-content: center;
    align-items: center;
  }
}
</style>