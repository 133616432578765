<template>
  <!-- <the-header></the-header> -->
  <router-view></router-view>
  <!-- <router-view v-slot="slotProps"> -->
    <!-- <transition :name="slotProps.route.meta.transitionName || 'none'"> -->
      <!-- <component :is="slotProps.Component"></component> -->
    <!-- </transition> -->
  <!-- </router-view> -->
</template>

<script>
export default {  
  mounted() {
    const script = document.createElement('script');
    script.src = 'https://cdn.userway.org/widget.js';
    script.setAttribute('data-account', process.env.VUE_APP_USERWAY_DATA_ACCOUNT);
    script.setAttribute('alt', 'Accessibility Menu');
    document.head.appendChild(script);
  }
}
</script>

<style>
/* #app {
  font-family: 'Times New Roman', Times, serif;  
  text-align: center;
  color: rgb(170, 0, 0);  
  margin:0;
  padding:0;
} */
/* body {
    position: no-repeat center center fixed;    
    background-image: url('~@/assets/background/b2-1.jpg');    
    opacity:.9;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
} */
html,
body,
#app {
  height: 100%;
  width: 100%;
  margin: 0px;
padding:0px;
overflow-y:hidden;
-webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;    /* Firefox, other Gecko */
  box-sizing: border-box; 
  background-color:rgb(221, 210, 178);
}
</style>
